import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalPhone } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import { finishAppointment } from '../../../api/appointment';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import { USER_TYPE } from '../../../utils/constants';
import EndCallReasonDialog from '../../EndCallReasonDialog/EndCallReasonDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            width: '56px',
            height: '56px',
            background: theme.brand,
            color: 'white',
            '&:hover': {
                background: '#600101',
            },
        },
    })
);

export default function EndCallButton(props: { className?: string }) {
    const {
        appointment,
        userType,
        setIsPatientEndCallDialogVisible,
        setIsDoctorEndCallDialogVisible,
    } = useAppState();
    const classes = useStyles();
    const { room } = useVideoContext();
    const [open, setOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<any>(null);

    const onClickDisconnect = () => {
        if (userType === USER_TYPE.DOCTOR) {
            setOpen(true);
        } else {
            room!.disconnect();
            setIsPatientEndCallDialogVisible(true);
        }
    };

    const handleClose = (value: any) => {
        setOpen(false);

        if (value) {
            setSelectedValue(value);

            finishAppointment(appointment.appointment_id, {
                complete_reason: value.reasonContent,
                additional_detail: value.additionalReason,
                medicare_code: value.medicareCode,
                medicare_appointment_type: value.medicareAppointmentType,
                medicare_appointment_duration:
                    value.medicareAppointmentDuration,
            });

            room!.disconnect();
            setIsDoctorEndCallDialogVisible(true);
        }
    };

    return (
        <div>
            <IconButton
                onClick={() => onClickDisconnect()}
                className={
                    clsx(classes.button, props.className) + ' end_call_btn'
                }
                color="secondary"
            >
                <LocalPhone />
            </IconButton>
            <EndCallReasonDialog
                open={open}
                selectedValue={selectedValue}
                onClose={handleClose}
            />
        </div>
    );
}

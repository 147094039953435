import { Snackbar } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Client } from '@twilio/conversations';
import { Conversation, Message } from '@twilio/conversations';
import React, {
    createContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { isMobile } from '../../utils';
import { USER_TYPE } from '../../utils/constants';
import './ChatProvider.scss';

type ChatContextType = {
    isChatWindowOpen: boolean;
    setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
    connect: (token: string) => void;
    hasUnreadMessages: boolean;
    messages: Message[];
    conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }) => {
    const { userType, appointment } = useAppState();
    const { room, onError } = useVideoContext();
    const isChatWindowOpenRef = useRef(false);
    const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
    const [conversation, setConversation] = useState<Conversation | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
    const [chatClient, setChatClient] = useState<Client>();
    const { isFullScreenEnabled, setIsFullScreenEnabled } = useAppState();
    const [open, setOpen] = useState(false);

    const isFullScreenEnabledRef = useRef(isFullScreenEnabled);

    useEffect(() => {
        isFullScreenEnabledRef.current = isFullScreenEnabled;
    });

    const connect = useCallback(
        (token: string) => {
            Client.create(token)
                .then((client) => {
                    //@ts-ignore
                    window.chatClient = client;
                    setChatClient(client);
                })
                .catch((e) => {
                    console.error(e);
                    onError(
                        new Error(
                            "There was a problem connecting to Twilio's conversation service."
                        )
                    );
                });
        },
        [onError]
    );

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    useEffect(() => {
        if (conversation) {
            const handleMessageAdded = (message: Message) => {
                setMessages((oldMessages) => [...oldMessages, message]);

                let currentUserEmail = '';

                if (userType === USER_TYPE.DOCTOR) {
                    currentUserEmail = appointment.doctor_user.email;
                } else {
                    currentUserEmail = appointment.user.email;
                }

                if (
                    (isFullScreenEnabledRef.current || isMobile) &&
                    message.author !== currentUserEmail
                ) {
                    setOpen(true);
                }
            };
            conversation
                .getMessages()
                .then((newMessages) => setMessages(newMessages.items));
            conversation.on('messageAdded', handleMessageAdded);
            return () => {
                conversation.off('messageAdded', handleMessageAdded);
            };
        }
    }, [conversation]);

    useEffect(() => {
        // If the chat window is closed and there are new messages, set hasUnreadMessages to true
        if (!isChatWindowOpenRef.current && messages.length) {
            setHasUnreadMessages(true);
        }
    }, [messages]);

    useEffect(() => {
        isChatWindowOpenRef.current = isChatWindowOpen;
        if (isChatWindowOpen) setHasUnreadMessages(false);
    }, [isChatWindowOpen]);

    useEffect(() => {
        if (room && chatClient) {
            chatClient
                .getConversationByUniqueName(room.name)
                .then((newConversation) => {
                    //@ts-ignore
                    window.chatConversation = newConversation;
                    setConversation(newConversation);
                })
                .catch((e) => {
                    console.error(e);
                    onError(
                        new Error(
                            'There was a problem getting the Conversation associated with this room.'
                        )
                    );
                });
        }
    }, [room, chatClient, onError]);

    const onNotificationClicked = () => {
        setIsFullScreenEnabled(false);
        setIsChatWindowOpen(true);
        setOpen(false);
    };

    return (
        <ChatContext.Provider
            value={{
                isChatWindowOpen,
                setIsChatWindowOpen,
                connect,
                hasUnreadMessages,
                messages,
                conversation,
            }}
        >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <div
                    className="unread-notification__container"
                    onClick={onNotificationClicked}
                >
                    <div className="unread-notification__inner-wrapper">
                        <NotificationsIcon />
                        <div className="unread-notification__message">
                            You have a message!
                        </div>
                    </div>
                </div>
            </Snackbar>
            {children}
        </ChatContext.Provider>
    );
};

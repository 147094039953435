import React from 'react';
import {
    AudioTrack as IAudioTrack,
    LocalTrackPublication,
    Participant,
    RemoteTrackPublication,
    Track,
} from 'twilio-video';
import useTrack from '../../hooks/useTrack/useTrack';
import { IVideoTrack } from '../../types';
import AudioTrack from '../AudioTrack/AudioTrack';
import VideoTrack from '../VideoTrack/VideoTrack';

interface PublicationProps {
    publication: LocalTrackPublication | RemoteTrackPublication;
    participant: Participant;
    isLocalParticipant?: boolean;
    videoOnly?: boolean;
    videoPriority?: Track.Priority | null;
    isScreenShareTrack?: boolean;
}

export default function Publication({
    publication,
    isLocalParticipant,
    videoOnly,
    videoPriority,
    isScreenShareTrack,
}: PublicationProps) {
    const track = useTrack(publication);

    if (!track) return null;

    switch (track.kind) {
        case 'video':
            return (
                <VideoTrack
                    isScreenShareTrack={isScreenShareTrack}
                    track={track as IVideoTrack}
                    priority={videoPriority}
                    isLocal={
                        !track.name.includes('screen') && isLocalParticipant
                    }
                />
            );
        case 'audio':
            return videoOnly ? null : (
                <AudioTrack track={track as IAudioTrack} />
            );
        default:
            return null;
    }
}

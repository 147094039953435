import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { ScreenShare, StopScreenShare } from '@material-ui/icons';
import React from 'react';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT =
    'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT =
    'Screen sharing is not supported with this browser';

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
    const screenShareParticipant = useScreenShareParticipant();
    const { toggleScreenShare, isSharingScreen } = useVideoContext();
    const disableScreenShareButton = Boolean(screenShareParticipant);
    const isScreenShareSupported =
        navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled =
        props.disabled || disableScreenShareButton || !isScreenShareSupported;

    let tooltipMessage = '';

    if (disableScreenShareButton) {
        tooltipMessage = SHARE_IN_PROGRESS_TEXT;
    }

    if (!isScreenShareSupported) {
        tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
    }

    return (
        <Tooltip
            title={tooltipMessage}
            placement="top"
            PopperProps={{ disablePortal: true }}
            className="btn-screen-share__tooltip"
        >
            <span>
                <IconButton
                    disabled={isDisabled}
                    color="secondary"
                    className="btn-screen-share"
                    onClick={toggleScreenShare}
                >
                    {isSharingScreen || disableScreenShareButton ? (
                        <StopScreenShare />
                    ) : (
                        <ScreenShare />
                    )}
                </IconButton>
            </span>
        </Tooltip>
    );
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Participant from '../Participant/Participant';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflowY: 'auto',
            background: 'white',
            gridArea: '1 / 2 / 1 / 3',
            zIndex: 5,
            [theme.breakpoints.down('sm')]: {
                gridArea: '2 / 1 / 3 / 3',
                overflowY: 'initial',
                overflowX: 'auto',
                display: 'flex',
            },
        },
        transparentBackground: {
            background: 'transparent',
        },
        greenBg: {
            backgroundColor: 'rgba(130, 199, 128, 1) !important',
        },
        scrollContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        innerScrollContainer: {
            width: `calc(${theme.sidebarWidth}px - 3em)`,
            padding: '1.5em 0',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                padding: `${theme.sidebarMobilePadding}px`,
                display: 'flex',
            },
        },
    })
);

export default function ParticipantList() {
    const classes = useStyles();
    const { room } = useVideoContext();
    const localParticipant = room!.localParticipant;
    const participants = useParticipants();
    const [selectedParticipant, setSelectedParticipant] =
        useSelectedParticipant();
    const screenShareParticipant = useScreenShareParticipant();
    const mainParticipant = useMainParticipant();
    const isRemoteParticipantScreenSharing = screenShareParticipant;

    if (!screenShareParticipant && participants.length === 0) return null; // Don't render this component if there are no remote participants.

    return (
        <aside
            className={clsx(classes.container, {
                [classes.transparentBackground]:
                    !isRemoteParticipantScreenSharing,
            })}
        >
            <div className={classes.scrollContainer}>
                <div className={classes.innerScrollContainer}>
                    <Participant
                        participant={localParticipant}
                        isLocalParticipant={true}
                    />
                    {participants.map((participant) => {
                        const isSelected = participant === selectedParticipant;
                        const hideParticipant = isRemoteParticipantScreenSharing
                            ? false
                            : participant === mainParticipant &&
                              participant !== screenShareParticipant &&
                              !isSelected;
                        return (
                            <Participant
                                key={participant.sid}
                                participant={participant}
                                isSelected={participant === selectedParticipant}
                                onClick={() =>
                                    setSelectedParticipant(participant)
                                }
                                hideParticipant={hideParticipant}
                            />
                        );
                    })}
                </div>
            </div>
        </aside>
    );
}

import { IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import MicIcon from '../../../assets/icons/MicIcon';
import MicOffIcon from '../../../assets/icons/MicOffIcon';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles(() => ({
    audioButton: {
        // marginBottom: '1em',
        width: '56px',
        height: '56px',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        transition: 'all .5s',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
    },
}));

export default function ToggleAudioButton(props: {
    disabled?: boolean;
    className?: string;
}) {
    const classes = useStyles();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const { localTracks } = useVideoContext();
    const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

    return (
        <IconButton
            disabled={!hasAudioTrack || props.disabled}
            color="secondary"
            className={clsx(props.className, classes.audioButton)}
            onClick={toggleAudioEnabled}
        >
            {isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
        </IconButton>
    );
}

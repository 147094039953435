import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isMobile } from '../../utils';
import { MEDIA_FILE_TYPE } from '../../utils/constants';
import './PreviewImage.scss';

interface PreviewImageProps {
    mediaFile: any;
    onRemove: () => void;
}

function PreviewImage(props: PreviewImageProps) {
    const [mediaFileUrl, setMediaFileUrl] = useState<string>('');
    const [fileType, setFileType] = useState<string>(MEDIA_FILE_TYPE.FILE);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setMediaFileUrl(URL.createObjectURL(props.mediaFile));

        let blobType = props?.mediaFile?.type ?? '';

        if (blobType.startsWith(MEDIA_FILE_TYPE.IMAGE)) {
            setFileType(MEDIA_FILE_TYPE.IMAGE);
        } else {
            setFileType(MEDIA_FILE_TYPE.FILE);
        }
    }, []);

    const onPreviewClick = () => {
        if (fileType === MEDIA_FILE_TYPE.FILE && isMobile) {
            window.open(mediaFileUrl, '_blank');
        } else {
            setOpen(true);
        }
    };

    const onDialogClose = () => {
        setOpen(false);
    };

    return (
        <div className="preview-image__container">
            <a
                className="close"
                onClick={() => {
                    props.onRemove();
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 46.49 46.49"
                >
                    <polygon
                        fill="#003d3b"
                        points="42.62 7.41 39.09 3.87 23.25 19.71 7.41 3.87 3.87 7.41 19.71 23.25 3.87 39.09 7.41 42.62 23.25 26.78 39.09 42.62 42.62 39.09 26.78 23.25 42.62 7.41"
                    />
                </svg>
            </a>
            {fileType === MEDIA_FILE_TYPE.IMAGE ? (
                <img
                    className="preview-img"
                    src={mediaFileUrl}
                    onClick={onPreviewClick}
                />
            ) : (
                <div className="preview-file" onClick={onPreviewClick}>
                    {props.mediaFile.name}
                </div>
            )}
            <Dialog
                open={open}
                onClose={onDialogClose}
                fullWidth={true}
                className={`${
                    fileType === MEDIA_FILE_TYPE.FILE
                        ? 'preview-popup--file'
                        : ''
                }`}
            >
                {fileType === MEDIA_FILE_TYPE.IMAGE ? (
                    <img src={mediaFileUrl} />
                ) : (
                    <object
                        className="preview-image__embed"
                        data={mediaFileUrl}
                    />
                )}
            </Dialog>
        </div>
    );
}

export default PreviewImage;

import React, { useEffect } from 'react';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import { isMobile } from '../../utils';
import ChatWindow from '../ChatWindow/ChatWindow';
import './PatientRightPanel.scss';

interface PatientRightPanelProps {
    name: string;
}

export default function PatientRightPanel({ name }: PatientRightPanelProps) {
    const { setIsChatWindowOpen } = useChatContext();

    useEffect(() => {
        setIsChatWindowOpen(isMobile ? false : true);
    }, []);

    return (
        <div className="patient-right-panel__container">
            <ChatWindow />
        </div>
    );
}

import React from 'react';
import { Participant as IParticipant } from 'twilio-video';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';

interface ParticipantProps {
    participant: IParticipant;
    videoOnly?: boolean;
    enableScreenShare?: boolean;
    onClick?: () => void;
    isSelected?: boolean;
    isLocalParticipant?: boolean;
    hideParticipant?: boolean;
}

export default function Participant({
    participant,
    videoOnly,
    enableScreenShare,
    onClick,
    isSelected,
    isLocalParticipant,
    hideParticipant,
}: ParticipantProps) {
    return (
        <ParticipantInfo
            participant={participant}
            onClick={onClick}
            isSelected={isSelected}
            isLocalParticipant={isLocalParticipant}
            hideParticipant={hideParticipant}
        >
            <ParticipantTracks
                participant={participant}
                videoOnly={videoOnly}
                enableScreenShare={enableScreenShare}
                isLocalParticipant={isLocalParticipant}
            />
        </ParticipantInfo>
    );
}

import { IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useRef } from 'react';
import VideoOffIcon from '../../../assets/icons/VideoOffIcon';
import VideoOnIcon from '../../../assets/icons/VideoOnIcon';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { useAppState } from '../../../state';

const useStyles = makeStyles(() => ({
    videoButton: {
        // marginBottom: '1em',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        transition: 'all .5s',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
    },
}));

export default function ToggleVideoButton(props: {
    disabled?: boolean;
    className?: string;
}) {
    const { appointment, userType } = useAppState();
    const classes = useStyles();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const { hasVideoInputDevices } = useDevices();

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    if (userType === 'user') {
        if (appointment.is_default_camera_off) {
            if (isVideoEnabled) {
                if (lastClickTimeRef.current === 0) {
                    toggleVideoEnabled();
                }
            }
        }
    }

    return (
        <IconButton
            disabled={!hasVideoInputDevices || props.disabled}
            color="secondary"
            className={clsx(props.className, classes.videoButton)}
            onClick={toggleVideo}
        >
            {isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
        </IconButton>
    );
}

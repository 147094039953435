import { Hidden, SwipeableDrawer } from '@material-ui/core';
import React from 'react';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import { useAppState } from '../../state';
import ChatWindow from '../ChatWindow/ChatWindow';
import './DoctorRightPanel.scss';
import DoctorRightPanelMainBlock from './DoctorRightPanelMainBlock';

interface DoctorRightPanelProps {
    name: string;
}

export default function DoctorRightPanel({ name }: DoctorRightPanelProps) {
    const { togglePatientInfo, setTogglePatientInfo } = useAppState();
    const { isChatWindowOpen } = useChatContext();

    const toggleDrawer = (e: any) => {
        setTogglePatientInfo(!togglePatientInfo);
    };

    return (
        <div>
            {isChatWindowOpen ? (
                <Hidden smDown>
                    <ChatWindow />
                </Hidden>
            ) : (
                <div>
                    <SwipeableDrawer
                        anchor="bottom"
                        disableSwipeToOpen={false}
                        open={togglePatientInfo}
                        onClose={toggleDrawer}
                        onOpen={toggleDrawer}
                        className="swipe-drawer-block"
                    >
                        <DoctorRightPanelMainBlock name={name} />
                    </SwipeableDrawer>
                    <div className="main-block">
                        <DoctorRightPanelMainBlock name={name} />
                    </div>
                </div>
            )}
        </div>
    );
}

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
    width: 1280,
    height: 720,
    frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY =
    'TwilioVideoApp-selectedBackgroundSettings';

export const REBOOK_URL = `${process.env.REACT_APP_USER_PORTAL_URL}/doctor/appointments/new`;
export const USER_HOME_URL = `${process.env.REACT_APP_USER_PORTAL_URL}/appointments`;
export const DOCTOR_HOME_URL = `${process.env.REACT_APP_USER_PORTAL_URL}/doctor/appointments`;
export const HOME_URL = `${process.env.REACT_APP_BASE_URL}`;

export const REASON_SELECT_LABEL = 'Select a reason for ending this call';

export const MOSH_USER_ACCESS_TYPE = {
    USER: 'User',
    DOCTOR: 'DoctorUser',
};

export const USER_TYPE = {
    USER: 'user',
    DOCTOR: 'doctor',
};

export const DOCTOR_TYPE = {
    GP: 'gp',
    PSYCHOLOGIST: 'psychologist',
    DIETITIAN: 'dietitian',
    NURSE_PRACTITIONER: 'nurse_practitioner',
    NON_GP: 'non_gp',
};

export const APPOINTMENT_STATUS = {
    COMPLETE: 'Appointment Complete',
    CANCELLED: 'Appointment Cancelled',
    IN_PROGRESS: 'Appointment Inprogress',
    CONFIRMED: 'Appointment Confirmed',
    MISSED: 'Appointment Missed',
};

export const ERROR_MESSAGE = {
    APPOINTMENT_ENDED: 'Appointment has ended',
    AUTH_FAILED: "Sorry, we can't validate your identity",
};

export const DEV_FIXED_USER_TOKEN = '-bPbkrZ5azX9M9swSHTz1535';
export const DEV_FIXED_DOCTOR_TOKEN = 'SBtyeXTnyaVyUk1qFMgq172';

export const MEDICARE_APPOINTMENT_TYPES = [
    {
        value: 'Phone',
    },
    {
        value: 'Video',
    },
];

export const MEDICARE_APPOINTMENT_DURATIONS = [
    {
        value: '<20 minutes',
    },
    {
        value: '20-40 minutes',
    },
    {
        value: '>40 minutes',
    },
];

export const SEX_MEDICARE_APPOINTMENT_DURATIONS = [
    {
        value: '<5 minutes',
    },
    {
        value: '5-20 minutes',
    },
    {
        value: '20-40 minutes',
    },
    {
        value: '>40 minutes',
    },
];

export const CONTACT_US_LINK = `${process.env.REACT_APP_USER_PORTAL_URL}/contact-us`;

export const WAITING_ROOM_MESSAGES = {
    DEFAULT:
        'Your consult will begin shortly. You will be automatically connected when the doctor is ready.',
    PATIENT_JOINED_BEFORE:
        'The doctor has started your session, you will be taken into the video session now',
    PATIENT_JOINED_AFTER:
        "The doctor is waiting. Please check your settings and click the 'join now' button below.",
};

export const GENDER = {
    MALE: 'male',
    FEMALE: 'female',
};

export const MEDIA_FILE_TYPE = {
    IMAGE: 'image',
    FILE: 'file',
};

export const BEST_BROWSERS_MESSAGE =
    'Note: The best browsers to support your video call are Chrome or Firefox.';

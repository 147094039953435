import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    Participant,
    RemoteAudioTrack,
    RemoteVideoTrack,
} from 'twilio-video';
import AvatarIcon from '../../assets/icons/AvatarIcon';
import useIsRecording from '../../hooks/useIsRecording/useIsRecording';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from '../../hooks/usePublications/usePublications';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useTrack from '../../hooks/useTrack/useTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { DOCTOR_TYPE, USER_TYPE } from '../../utils/constants';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import '../ParticipantInfo/ParticipantInfo.scss';

interface MainParticipantInfoProps {
    participant: Participant;
    children: React.ReactNode;
}

export default function MainParticipantInfo({
    participant,
    children,
}: MainParticipantInfoProps) {
    const { appointment, userType } = useAppState();
    const { room } = useVideoContext();
    const localParticipant = room!.localParticipant;
    const isLocal = localParticipant === participant;

    const screenShareParticipant = useScreenShareParticipant();
    const isRemoteParticipantScreenSharing = screenShareParticipant;

    const publications = usePublications(participant);
    const localPublications = usePublications(localParticipant);
    const videoPublication = publications.find(
        (p) => !p.trackName.includes('screen') && p.kind === 'video'
    );
    const screenSharePublication = publications.find((p) =>
        p.trackName.includes('screen')
    );
    const localScreenPublication = localPublications.find((p) =>
        p.trackName.includes('screen')
    );
    const isScreenSharing = screenSharePublication || localScreenPublication;

    const videoTrack = useTrack(
        localScreenPublication || screenSharePublication || videoPublication
    );
    const isVideoEnabled = Boolean(videoTrack);

    const audioPublication = publications.find((p) => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication) as
        | LocalAudioTrack
        | RemoteAudioTrack
        | undefined;

    const isVideoSwitchedOff = useIsTrackSwitchedOff(
        videoTrack as LocalVideoTrack | RemoteVideoTrack
    );
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);

    const isRecording = useIsRecording();

    const [identity, setIdentity] = useState('');
    const [doctorIdenttity, setDoctorIdentity] = useState('');

    let isGp =
        userType === USER_TYPE.DOCTOR &&
        appointment.doctor_user.doctor_type.toLocaleLowerCase() ===
            DOCTOR_TYPE.GP;

    useEffect(() => {
        if (participant.identity === appointment.user.email) {
            setIdentity(appointment.user.name);
        } else if (participant.identity === appointment.doctor_user.email) {
            setIdentity(
                `${isGp ? 'Dr ' : ''} ${appointment.doctor_user.first_name} ${
                    appointment.doctor_user.last_name
                }`
            );
        } else {
            setIdentity(participant.identity);
        }
    }, [participant, appointment]);

    useEffect(() => {
        setDoctorIdentity(
            `${isGp ? 'Dr ' : ''} ${appointment.doctor_user.first_name} ${
                appointment.doctor_user.last_name
            }`
        );
    }, [participant, appointment]);

    return (
        <div
            data-cy-main-participant
            data-cy-participant={participant.identity}
            className={`main-participant-info ${
                !isRemoteParticipantScreenSharing ? 'full-width' : ''
            }`}
        >
            <div className="main-participant-info__container">
                <div>
                    <div className="identity">
                        <AudioLevelIndicator audioTrack={audioTrack} />
                        <Typography variant="body1" color="inherit">
                            {isScreenSharing ? doctorIdenttity : identity}
                            {isLocal && ' (You)'}
                            {isScreenSharing && ' - Screen'}
                        </Typography>
                    </div>
                    <NetworkQualityLevel participant={participant} />
                </div>
                {isRecording && (
                    <Tooltip
                        title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
                        placement="top"
                    >
                        <div className="main-participant-info__recording-indicator">
                            <div className="circle"></div>
                            <Typography
                                variant="body1"
                                color="inherit"
                                data-cy-recording-indicator
                            >
                                Recording
                            </Typography>
                        </div>
                    </Tooltip>
                )}
            </div>
            {(!isVideoEnabled || isVideoSwitchedOff) && (
                <div className="main-participant-info__avatar-container">
                    <AvatarIcon />
                </div>
            )}
            {isParticipantReconnecting && (
                <div className="main-participant-info__reconnecting-container">
                    <Typography variant="body1" className="content">
                        Reconnecting...
                    </Typography>
                </div>
            )}
            {children}
        </div>
    );
}

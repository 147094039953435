import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    generateVcAccessToken,
    getAppointmentById,
    startAppointment,
} from '../../../api/appointment';
import { createAppointmentLog } from '../../../api/appointment_log';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import { checkIsEarlyAppointment, formatAppointmentTime } from '../../../utils';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { Steps } from '../PreJoinScreens';
import './InitialScreen.scss';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
interface DeviceSelectionScreenProps {
    name: string;
    roomName: string;
    setStep: (step: Steps) => void;
}

function DoctorInitialScreen({ name }: DeviceSelectionScreenProps) {
    const { appointment, vcUser, setAppointment } = useAppState();
    const { isFetching } = useAppState();
    const { connect: chatConnect } = useChatContext();
    const {
        connect: videoConnect,
        isAcquiringLocalTracks,
        isConnecting,
    } = useVideoContext();
    const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
    const { getAudioAndVideoTracks } = useVideoContext();
    const [loading, setLoading] = useState(false);
    const [isCheckDevice, setIsCheckDevice] = useState(false);
    const [isEarlyAppointment, setIsEarlyAppointment] =
        useState<boolean>(false);
    const [formattedAppointmentTime, setFormattedAppointmentTime] =
        useState<string>('');
    const [earlyAppointmentInterval, setEarlyAppointmentInterval] =
        useState<any>(null);
    const [joinBtnVisible, setJoinBtnVisible] = useState<boolean>(false);
    const { UrlAppointmentId } = useParams<{
        UrlAppointmentId: string;
    }>();

    const earlyAppointmentIntervalRef = useRef(earlyAppointmentInterval);

    useEffect(() => {
        earlyAppointmentIntervalRef.current = earlyAppointmentInterval;
    });

    useEffect(() => {
        if (appointment) {
            let isEarly = checkIsEarlyAppointment(appointment);

            setIsEarlyAppointment(isEarly);

            if (isEarly) {
                setFormattedAppointmentTime(
                    formatAppointmentTime(appointment.appointment_time)
                );
            }
        }
    }, [appointment]);

    useEffect(() => {
        getAppointmentById({
            appointmentId: UrlAppointmentId,
        }).then(async (response: any) => {
            let startTime = dayjs(response.appointment_time);

            checkTime(startTime);

            let interval = setInterval(async () => {
                checkTime(startTime);
            }, 5000);

            setEarlyAppointmentInterval(interval);
        });

        return () => {
            clearEarlyAppointmentInterval();
        };
    }, []);

    const checkTime = (startTime: any) => {
        let now = dayjs();
        let diff = startTime.diff(now);

        let seconds = diff / 1000;

        if (seconds <= 120 * 60) {
            setJoinBtnVisible(true);
            clearEarlyAppointmentInterval();
        }
    };

    const clearEarlyAppointmentInterval = () => {
        if (earlyAppointmentIntervalRef.current) {
            clearInterval(earlyAppointmentIntervalRef.current);
            setEarlyAppointmentInterval(null);
        }
    };

    const handleJoin = () => {
        setLoading(true);

        startAppointment({
            appointmentId: appointment.appointment_id,
        })
            .then((response: any) => {
                setAppointment(response);

                generateVcAccessToken({
                    identity: vcUser?.email ?? '',
                    room_name: response?.vc_room_name ?? '',
                })
                    .then((response2: any) => {
                        videoConnect(response2.access_token);
                        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !==
                            'true' && chatConnect(response2.access_token);
                        createAppointmentLog({
                            appointment_id: appointment.appointment_id,
                            action_name: 'Doctor joined',
                            creator_type: 'DoctorUser',
                            creator_uuid: appointment?.doctor_user?.uuid ?? '',
                        });
                    })
                    .catch((e2) => {
                        console.error(e2);
                        setLoading(false);
                    });
            })
            .catch((e) => {
                console.error(e);
                setLoading(false);
            });
    };

    const onClickTestDevice = () => {
        setIsCheckDevice(!isCheckDevice);
    };

    useEffect(() => {
        getAudioAndVideoTracks().catch((e) => {
            console.log('Error acquiring local media:');
            console.dir(e);
        });
    }, [getAudioAndVideoTracks]);

    if (isFetching || isConnecting) {
        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="loading-spinner__container"
            >
                <div className="loading-spinner--large">
                    <CircularProgress size={30} variant="indeterminate" />
                </div>
                <div>
                    <Typography variant="body2">
                        <strong>Joining meeting</strong>
                    </Typography>
                </div>
            </Grid>
        );
    }

    return (
        <>
            <Typography variant="h2" className="user_name">
                Appointment with patient {appointment?.user?.name ?? ''}
            </Typography>

            {isEarlyAppointment && (
                <div className="consult-message">
                    Your appointment will begin on {formattedAppointmentTime}
                </div>
            )}

            <div>
                <h5
                    className="margin-1 link-device-test link-device-test-doctor"
                    onClick={onClickTestDevice}
                >
                    Test speaker and microphone
                </h5>

                <Grid
                    container
                    justifyContent="center"
                    className="test_buttons"
                >
                    <Grid item md={12} sm={12} xs={12}>
                        {isCheckDevice && (
                            <div className="video-preview__container">
                                <div className="video-preview">
                                    <LocalVideoPreview identity={name} />
                                </div>
                                <div className="btn-mobile-container">
                                    <Hidden mdUp>
                                        <ToggleAudioButton
                                            disabled={disableButtons}
                                        />
                                        <ToggleVideoButton
                                            disabled={disableButtons}
                                        />
                                    </Hidden>
                                </div>
                                {isCheckDevice && (
                                    <div className="video-preview__buttons text-align-center">
                                        <Hidden smDown>
                                            <ToggleAudioButton
                                                disabled={disableButtons}
                                            />
                                            <ToggleVideoButton
                                                disabled={disableButtons}
                                            />
                                        </Hidden>
                                    </div>
                                )}
                            </div>
                        )}
                        {joinBtnVisible && (
                            <div className="text-align-center">
                                {/*<Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>*/}
                                {/*  Cancel*/}
                                {/*</Button>*/}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="margin-1"
                                    data-cy-join-now
                                    onClick={handleJoin}
                                    disabled={loading || disableButtons}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress
                                                color="primary"
                                                size={12}
                                            />
                                        ) : (
                                            ''
                                        )
                                    }
                                >
                                    Start Now
                                </Button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default DoctorInitialScreen;

import {
    CircularProgress,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getAppointmentById } from '../../api/appointment';
import { authMoshUser } from '../../api/auth';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import {
    APPOINTMENT_STATUS,
    ERROR_MESSAGE,
    MOSH_USER_ACCESS_TYPE,
    USER_HOME_URL,
    USER_TYPE,
} from '../../utils/constants';
import IntroContainer from '../IntroContainer/IntroContainer';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import DoctorInitialScreen from './DeviceSelectionScreen/DoctorInitialScreen';
import PatientInitialScreen from './DeviceSelectionScreen/PatientInitialScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';

export enum Steps {
    roomNameStep,
    deviceSelectionStep,
    userInitialScreen,
    doctorInitialScreen,
    welcomeScreen,
}

const useStyles = makeStyles(() => ({
    errorContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        flex: 1,
        textAlign: 'center',
        fontSize: '16px',
        padding: '4rem 2rem 3rem 2rem',
    },
    welcomeScreenContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        flex: 1,
        textAlign: 'center',
        fontSize: '1.25rem',
    },
    spinner: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // marginTop: -12,
        // marginLeft: -12,
    },
}));

export default function PreJoinScreens() {
    const classes = useStyles();
    const { appointment, user, setAppointment, setVcUser, setUserType } =
        useAppState();
    const { getAudioAndVideoTracks } = useVideoContext();
    const { UrlAppointmentId } = useParams<{
        UrlAppointmentId: string;
    }>();

    const [step, setStep] = useState(Steps.welcomeScreen);

    const [name, setName] = useState<string>(user?.displayName || '');
    const [roomName, setRoomName] = useState<string>('');

    const [mediaError, setMediaError] = useState<Error>();

    const search = useLocation().search;
    const userType = new URLSearchParams(search).get('user_type');

    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(
        ERROR_MESSAGE.AUTH_FAILED
    );

    // Check need to show error message or not
    useEffect(() => {
        if (
            appointment &&
            appointment.status !== APPOINTMENT_STATUS.CONFIRMED &&
            appointment.status !== APPOINTMENT_STATUS.IN_PROGRESS
        ) {
            setErrorMessage(ERROR_MESSAGE.APPOINTMENT_ENDED);
            setShowError(true);
        }
    }, [appointment]);

    useEffect(() => {
        if (UrlAppointmentId) {
            let isUser = true;

            let userToken = Cookies.get(
                process.env.REACT_APP_COOKIE_PREFIX + 'mosh_ut'
            );
            let doctorToken = Cookies.get(
                process.env.REACT_APP_COOKIE_PREFIX + 'mosh_dt'
            );

            if (userToken !== 'undefined' && userToken !== undefined) {
                isUser = true;
            } else if (
                doctorToken !== undefined &&
                doctorToken !== 'undefined'
            ) {
                isUser = false;
            }

            const validateIdentity = async () => {
                let result = null;

                if (userToken !== 'undefined' && userToken !== undefined) {
                    result = await authMoshUser({
                        access_type: MOSH_USER_ACCESS_TYPE.USER,
                        access_token: userToken,
                    });
                } else if (
                    doctorToken !== undefined &&
                    doctorToken !== 'undefined'
                ) {
                    result = await authMoshUser({
                        access_type: MOSH_USER_ACCESS_TYPE.DOCTOR,
                        access_token: doctorToken,
                    });
                } else {
                    throw new Error('No token found');
                }

                return result;
            };

            try {
                validateIdentity()
                    .then((response1: any) => {
                        getAppointmentById({
                            appointmentId: UrlAppointmentId,
                        }).then((response: any) => {
                            if (
                                response.status ===
                                APPOINTMENT_STATUS.CONFIRMED ||
                                response.status ===
                                APPOINTMENT_STATUS.IN_PROGRESS
                            ) {
                                // Only do the validation in non-dev environment
                                if (isUser) {
                                    if (response1.uuid !== response.user.uuid) {
                                        setShowError(true);
                                    } else {
                                        setUserType(USER_TYPE.USER);
                                        setStep(Steps.userInitialScreen);
                                        setVcUser(response.user);
                                    }
                                } else {
                                    if (
                                        response1.uuid !==
                                        response.doctor_user.uuid
                                    ) {
                                        setShowError(true);
                                    } else {
                                        setUserType(USER_TYPE.DOCTOR);
                                        setVcUser(response.doctor_user);
                                        setStep(Steps.doctorInitialScreen);
                                    }
                                }

                                setAppointment(response);
                            } else {
                                setErrorMessage(
                                    ERROR_MESSAGE.APPOINTMENT_ENDED
                                );
                                setShowError(true);
                            }
                        });
                    })
                    .catch((error) => {
                        setShowError(true);
                    });
            } catch (e) {
                setShowError(true);
            }
        } else {
            setShowError(true);
        }

        return () => { };
    }, [UrlAppointmentId, setAppointment, setUserType, setVcUser, userType]);

    useEffect(() => {
        if (step === Steps.deviceSelectionStep && !mediaError) {
            getAudioAndVideoTracks().catch((error) => {
                console.log('Error acquiring local media:');
                console.dir(error);
                setMediaError(error);
            });
        }
    }, [getAudioAndVideoTracks, step, mediaError]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
        if (!window.location.origin.includes('twil.io')) {
            window.history.replaceState(
                null,
                '',
                window.encodeURI(
                    `/room/${roomName}${window.location.search || ''}`
                )
            );
        }
        setStep(Steps.deviceSelectionStep);
    };

    return (
        <IntroContainer>
            <MediaErrorSnackbar error={mediaError} />

            {showError ? (
                <div className={classes.errorContainer}>{errorMessage}</div>
            ) : (
                <div>
                    {step === Steps.welcomeScreen && (
                        <div
                            className={
                                classes.welcomeScreenContainer +
                                ' loading-spinner__container'
                            }
                        >
                            <div className="loading-spinner--large">
                                <CircularProgress
                                    size={30}
                                    className={classes.spinner}
                                />
                            </div>
                            <div>
                                <Typography variant="body2">
                                    <strong>Loading</strong>
                                </Typography>
                            </div>
                        </div>
                    )}

                    {step === Steps.roomNameStep && (
                        <RoomNameScreen
                            name={name}
                            roomName={roomName}
                            setName={setName}
                            setRoomName={setRoomName}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    {step === Steps.doctorInitialScreen && (
                        <DoctorInitialScreen
                            name={name}
                            roomName={roomName}
                            setStep={setStep}
                        />
                    )}

                    {step === Steps.userInitialScreen && (
                        <PatientInitialScreen
                            name={name}
                            roomName={roomName}
                            setStep={setStep}
                        />
                        // <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
                    )}

                    {step === Steps.deviceSelectionStep && (
                        <DeviceSelectionScreen
                            name={name}
                            roomName={roomName}
                            setStep={setStep}
                        />
                    )}
                </div>
            )}
        </IntroContainer>
    );
}

import React from 'react';

export default function MicIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <defs></defs>
            <path
                fill="#fff"
                d="M78.76,40.25V52.93A28.79,28.79,0,0,1,53.3,81.49a3.41,3.41,0,0,1,.2,1.11v6.14h8.25a3.5,3.5,0,1,1,0,7H38.25a3.5,3.5,0,0,1,0-7H46.5V82.6a3.41,3.41,0,0,1,.2-1.11A28.79,28.79,0,0,1,21.24,52.93V40.25a3.5,3.5,0,0,1,7,0V52.93a21.76,21.76,0,0,0,43.52,0V40.25a3.5,3.5,0,0,1,7,0ZM50,67.67A14.75,14.75,0,0,0,64.75,52.93V17.58a14.75,14.75,0,0,0-29.5,0V52.93A14.75,14.75,0,0,0,50,67.67Z"
            />
        </svg>
    );
}

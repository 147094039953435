export const MOSH_STYLE = {
    moshSecondaryBtn: {
        backgroundColor: '#ff6340',
        color: 'white',
        // boxShadow: '#003d3b 8px 8px',
        transition: 'all .1s',
        '&:hover': {
            backgroundColor: '#ff6340',
            // boxShadow: '#003d3b 0px 0px'
        },
        '&.Mui-disabled': {
            backgroundColor: 'darkgrey',
        },
    },
};

import { SwipeableDrawer } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
    createStyles,
    makeStyles,
    styled,
    Theme,
} from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAppointmentById } from './api/appointment';
import './App.scss';
import { MOSH_STYLE } from './assets/style/moshStyle';
import ChatWindow from './components/ChatWindow/ChatWindow';
import DoctorEndCallDialog from './components/DoctorEndCallDialog/DoctorEndCallDialog';
import DoctorNoteSection from './components/DoctorNoteSection/DoctorNoteSection';
import PatientEndCallDialog from './components/PatientEndCallDialog/PatientEndCallDialog';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import DoctorRightPanel from './components/RightPanel/DoctorRightPanel';
import PatientRightPanel from './components/RightPanel/PatientRightPanel';
import Room from './components/Room/Room';
import TopNavBar from './components/TopNavBar/TopNavBar';
import useChatContext from './hooks/useChatContext/useChatContext';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import { useAppState } from './state';
import { isMobile } from './utils';
import { APPOINTMENT_STATUS, USER_TYPE } from './utils/constants';

const Container = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
    backgroundColor: '#f0f4f6',
    // opacity: 0.9,
    // backgroundImage: 'radial-gradient(#8f8f8f 0.5px, transparent 0.5px), radial-gradient(#8f8f8f 0.5px, #d9d9d9 0.5px)',
    // backgroundSize: '20px 20px',
    // backgroundPosition: '0 0,10px 10px',
    overflow: 'hidden',
    // paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
    // background: 'black',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: `${
            theme.mobileFooterHeight + theme.mobileTopBarHeight
        }px`, // Leave some space for the mobile header and footer
    },
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gutter: {
            margin: '1em',
        },
        videoContainer: {
            // width: 'calc(100vw - 32px)',
            position: 'relative',
            // borderRadius: '32px',
            // padding: '5px',
            // margin: '16px'
            [theme.breakpoints.down('sm')]: {
                borderRadius: '0',
            },
        },
        videoWrapper: {
            margin: '8px',
            borderRadius: '8px',
            overflow: 'hidden',
            perspective: '1px',
            backgroundColor: 'white',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: 0,
                borderRadius: 0,
            },
        },
        block: {
            padding: '2px',
            backgroundColor: 'white',
            margin: '32px 16px',
            borderRadius: '8px',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                borderRadius: 0,
                width: '100vw',
                padding: 0,
            },
        },
        patientName: {
            color: theme.palette.primary.main,
            fontWeight: 800,
            fontSize: '32px',
            textAlign: 'center',
            fontFamily: 'MaisonNeueBook',
        },
        infoItem: {
            textAlign: 'center',
            color: theme.palette.primary.main,
            margin: '8px 0',
            fontSize: '14px',
        },
        noteSection: {
            margin: '16px',
        },
        title: {
            fontSize: '18px',
        },
        divider: {
            margin: '16px 0',
        },
        infoTabRoot: {
            margin: '16px 0',
            borderRadius: '16px',
            boxShadow:
                '0px 0px 13px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        },
        infoTabItemLabel: {
            color: 'grey',
        },
        infoTabItemContent: {
            color: theme.palette.primary.main,
            fontSize: '20px',
            fontWeight: 500,
        },
        infoTabItem: {
            margin: '16px',
        },
        rightBlock: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                left: 0,
                bottom: 0,
            },
        },
        rootPaper: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
        },
        moshSecondaryBtn: MOSH_STYLE.moshSecondaryBtn,
        endCallDialogContent: {
            marginTop: '2rem',
            textAlign: 'center',
            fontFamily: 'MaisonNeueBook',
            color: theme.palette.primary.main,
            fontWeight: 500,
            fontSize: '16px',
        },
    })
);

export default function App() {
    const {
        appointment,
        userType,
        isFullScreenEnabled,
        setAppointment,
        setIsPatientEndCallDialogVisible,
        setIsDoctorEndCallDialogVisible,
    } = useAppState();
    const { isChatWindowOpen, setIsChatWindowOpen } = useChatContext();
    const classes = useStyles();
    const roomState = useRoomState();
    const [chatName, setChatName] = useState('');
    const { UrlAppointmentId } = useParams<{ UrlAppointmentId: string }>();
    // Here we would like the height of the main container to be the height of the viewport.
    // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
    // not the viewport. This looks bad when the mobile browsers location bar is open.
    // We will dynamically set the height with 'window.innerHeight', which means that this
    // will look good on mobile browsers even after the location bar opens or closes.
    const height = useHeight();

    useEffect(() => {
        let firstName = '';

        if (userType === USER_TYPE.USER) {
            firstName = appointment?.doctor_user?.first_name ?? '';
        } else {
            firstName = appointment?.user?.first_name ?? '';
        }

        setChatName(_.capitalize(firstName));
    }, [appointment, userType]);

    useEffect(() => {
        let interval: any = null;

        if (UrlAppointmentId) {
            interval = setInterval(async () => {
                let appointmentResponse: any = await getAppointmentById({
                    appointmentId: UrlAppointmentId,
                });

                setAppointment(appointmentResponse);

                if (
                    APPOINTMENT_STATUS.COMPLETE ===
                        appointmentResponse.status ||
                    APPOINTMENT_STATUS.CANCELLED ===
                        appointmentResponse.status ||
                    APPOINTMENT_STATUS.MISSED === appointmentResponse.status
                ) {
                    if (USER_TYPE.USER === userType) {
                        setIsPatientEndCallDialogVisible(true);
                    } else if (USER_TYPE.DOCTOR === userType) {
                        setIsDoctorEndCallDialogVisible(true);
                    }

                    if (interval) {
                        clearInterval(interval);
                    }
                }
            }, 5000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [UrlAppointmentId, userType]);

    const toggleDrawer = (e: any) => {
        setIsChatWindowOpen(!isChatWindowOpen);
    };

    return (
        <Container style={{ height }}>
            <DoctorEndCallDialog />
            <PatientEndCallDialog />
            {roomState === 'disconnected' ? (
                <PreJoinScreens />
            ) : (
                <Main>
                    <TopNavBar />
                    {/*<ReconnectingNotification />*/}
                    {/*<RecordingNotifications />*/}
                    <div className="video-grid__container">
                        <Grid
                            container
                            direction="row"
                            spacing={isMobile ? 0 : 3}
                        >
                            <Grid
                                item
                                md={isFullScreenEnabled ? 12 : 8}
                                sm={12}
                            >
                                <div className={classes.block + ' video_block'}>
                                    <div className={classes.videoWrapper}>
                                        <div
                                            className={
                                                classes.videoContainer +
                                                ' video_container'
                                            }
                                        >
                                            {/*<MobileTopMenuBar />*/}
                                            <Room />
                                        </div>
                                        <div className="doctor-note-section--desktop">
                                            {userType === USER_TYPE.DOCTOR && (
                                                <DoctorNoteSection />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                sm={12}
                                className={classes.rightBlock}
                            >
                                <div>
                                    {userType === USER_TYPE.DOCTOR ? (
                                        <DoctorRightPanel name={chatName} />
                                    ) : (
                                        <PatientRightPanel name={chatName} />
                                    )}
                                </div>
                            </Grid>
                            <SwipeableDrawer
                                anchor="bottom"
                                disableSwipeToOpen={false}
                                open={isChatWindowOpen}
                                onClose={toggleDrawer}
                                onOpen={toggleDrawer}
                                className="swipe-drawer-block"
                            >
                                <div>
                                    <ChatWindow />
                                </div>
                            </SwipeableDrawer>
                        </Grid>
                    </div>
                </Main>
            )}
        </Container>
    );
}

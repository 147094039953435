import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PatientEndScreen from '../../assets/images/PatientEndScreen.svg';
import { useAppState } from '../../state';
import {
    CONTACT_US_LINK,
    HOME_URL,
    USER_HOME_URL,
} from '../../utils/constants';
import './PatientEndCallDialog.scss';

export default function PatientEndCallDialog() {
    const { isPatientEndCallDialogVisible } = useAppState();

    const onClickBackToYourAccount = (e: any) => {
        e.preventDefault();
        window.location.href = USER_HOME_URL;
    };

    return (
        <Dialog
            open={isPatientEndCallDialogVisible}
            fullWidth={true}
            maxWidth="xs"
            className="patient-end-screen-dialog"
        >
            <AppBar position="static" className="inner-block">
                <Toolbar>
                    <a href={HOME_URL}>
                        <Typography variant="h6" className="logo">
                            <img
                                alt="mosh logo"
                                src={
                                    'https://app.getmosh.com.au/assets/logo/logo_dark-a8f123fc6a5cead8cbe90b9d4729a824d99f5ec1fe7b8d4548f6ff46c9096564.svg'
                                }
                            ></img>
                        </Typography>
                    </a>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <img src={PatientEndScreen} />
                <h4 className="header1">
                    <div>Thanks for stopping by!</div>
                </h4>
                <div className="content">
                    <div>
                        You will receive an email soon with any follow
                        up&nbsp;instructions.
                    </div>

                    <div>
                        If you have any questions, reach out to our friendly
                        customer support team <a href={CONTACT_US_LINK}>here</a>
                        .
                    </div>
                </div>
                <Button className="btn--lrg" onClick={onClickBackToYourAccount}>
                    Back to account
                </Button>
            </DialogContent>
        </Dialog>
    );
}

import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './GameSnake.scss';

interface GameShakeProps {
    onExitGame: () => void;
}

function GameSnake(props: GameShakeProps) {
    const [showGameOver, setShowGameOver] = useState(false);
    const [startGame, setStartGame] = useState(true);
    const [playCount, setPlayCount] = useState(0);

    // @ts-ignore
    var sn = [2, 1],
        dz = 3,
        fx = 1,
        n;

    const keyDownEventListener = (e: KeyboardEvent) => {
        // eslint-disable-next-line no-restricted-globals
        fx =
            sn[1] - sn[0] === (n = [-1, -15, 1, 15][e.keyCode - 37] || fx)
                ? fx
                : n;
        if (
            ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(
                e.code
            ) > -1
        ) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        let interval: any = null;

        if (startGame) {
            // @ts-ignore
            var ctx = document.getElementById('can').getContext('2d');

            document.addEventListener('keydown', keyDownEventListener);

            function draw(t: any, c: any) {
                ctx.fillStyle = c;
                ctx.fillRect((t % 15) * 15 + 1, ~~(t / 15) * 15 + 1, 14, 14);
            }

            const initial = () => {
                sn.unshift((n = sn[0] + fx));
                if (
                    sn.indexOf(n, 1) > 0 ||
                    n < 0 ||
                    n > 224 ||
                    (fx === 1 && n % 15 === 0) ||
                    (fx === -1 && n % 15 === 14)
                ) {
                    setShowGameOver(true);
                    setStartGame(false);
                    if (interval) {
                        clearInterval(interval);
                    }
                    setPlayCount(playCount + 1);
                    return;
                }
                draw(n, '#91c487');
                if (n === dz) {
                    while (sn.indexOf((dz = ~~(Math.random() * 225))) >= 0);
                    draw(dz, '#ff6340');
                } else draw(sn.pop(), '#003d3b');
            };

            interval = setInterval(initial, 130);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
            document.removeEventListener('keydown', keyDownEventListener);
        };
    }, [startGame, playCount]);

    const onClickExitGame = () => {
        document.removeEventListener('keydown', keyDownEventListener);
        props.onExitGame();
    };

    const onClickStartGame = () => {
        let canvas = document.getElementById('can');
        // @ts-ignore
        const context = document.getElementById('can').getContext('2d');
        // @ts-ignore
        context.clearRect(0, 0, canvas.width, canvas.height);
        setShowGameOver(false);
        setStartGame(true);
    };

    return (
        <div>
            <div className="game-snake-block">
                <div className="game-snake-block__game-over">
                    <h2>{showGameOver ? 'Game Over' : ''}</h2>
                    <a className="close" onClick={onClickExitGame}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 46.49 46.49"
                        >
                            <polygon
                                fill="#003d3b"
                                points="42.62 7.41 39.09 3.87 23.25 19.71 7.41 3.87 3.87 7.41 19.71 23.25 3.87 39.09 7.41 42.62 23.25 26.78 39.09 42.62 42.62 39.09 26.78 23.25 42.62 7.41"
                            />
                        </svg>
                    </a>
                    {startGame ? (
                        <span></span>
                    ) : (
                        <Button
                            className="btn--ghost"
                            onClick={onClickStartGame}
                            disabled={startGame}
                        >
                            {playCount > 0 ? 'Restart game' : 'Start game'}
                        </Button>
                    )}
                </div>
                <canvas id="can" width="225px" height="225px"></canvas>
            </div>
        </div>
    );
}

export default GameSnake;

import {
    GaussianBlurBackgroundProcessor,
    ImageFit,
    isSupported,
    VirtualBackgroundProcessor,
} from '@twilio/video-processors';
import { useCallback, useEffect, useState } from 'react';
import { LocalVideoTrack, Room } from 'twilio-video';

import Blur1 from '../../../assets/images/Vc_Bg_1.jpg';
import Blur2 from '../../../assets/images/Vc_Bg_2.jpg';
import BohoHome from '../../../assets/images/BohoHome.jpg';
import CozyHome from '../../../assets/images/CozyHome.jpg';
import Kitchen from '../../../assets/images/Kitchen.jpg';
import ModernHome from '../../../assets/images/ModernHome.jpg';
import Contemporary from '../../../assets/images/Contemporary.jpg';

import Blur1Thumb from '../../../assets/images/Vc_Bg_1.jpg';
import Blur2Thumb from '../../../assets/images/Vc_Bg_2.jpg';
import BohoHomeThumb from '../../../assets/images/thumb/BohoHome.jpg';
import CozyHomeThumb from '../../../assets/images/thumb/CozyHome.jpg';
import KitchenThumb from '../../../assets/images/thumb/Kitchen.jpg';
import ModernHomeThumb from '../../../assets/images/thumb/ModernHome.jpg';
import ContemporaryThumb from '../../../assets/images/thumb/Contemporary.jpg';

import { SELECTED_BACKGROUND_SETTINGS_KEY } from '../../../utils/constants';
import { Thumbnail } from '../../BackgroundSelectionDialog/BackgroundThumbnail/BackgroundThumbnail';

export interface BackgroundSettings {
    type: Thumbnail;
    index?: number;
}

const imageNames: string[] = [
    'Blur1',
    'Blur2',
    'BohoHome',
    'CozyHome',
    'Kitchen',
    'ModernHome',
    'Contemporary',
];

const images = [
    Blur1Thumb,
    Blur2Thumb,
    BohoHomeThumb,
    CozyHomeThumb,
    KitchenThumb,
    ModernHomeThumb,
    ContemporaryThumb,
];

const rawImagePaths = [
    Blur1,
    Blur2,
    BohoHome,
    CozyHome,
    Kitchen,
    ModernHome,
    Contemporary,
];

let imageElements = new Map();

const getImage = (index: number): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        if (imageElements.has(index)) {
            return resolve(imageElements.get(index));
        }
        const img = new Image();
        img.onload = () => {
            imageElements.set(index, img);
            resolve(img);
        };
        img.onerror = reject;
        img.src = rawImagePaths[index];
    });
};

export const backgroundConfig = {
    imageNames,
    images,
};

const virtualBackgroundAssets = '/virtualbackground';
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

export default function useBackgroundSettings(
    videoTrack: LocalVideoTrack | undefined,
    room?: Room | null
) {
    const [backgroundSettings, setBackgroundSettings] =
        useState<BackgroundSettings>(() => {
            const localStorageSettings = window.localStorage.getItem(
                SELECTED_BACKGROUND_SETTINGS_KEY
            );
            return localStorageSettings
                ? JSON.parse(localStorageSettings)
                : { type: 'none', index: 0 };
        });

    const removeProcessor = useCallback(() => {
        if (videoTrack && videoTrack.processor) {
            videoTrack.removeProcessor(videoTrack.processor);
        }
    }, [videoTrack]);

    const addProcessor = useCallback(
        (
            processor:
                | GaussianBlurBackgroundProcessor
                | VirtualBackgroundProcessor
        ) => {
            if (!videoTrack || videoTrack.processor === processor) {
                return;
            }
            removeProcessor();
            videoTrack.addProcessor(processor);
        },
        [videoTrack, removeProcessor]
    );

    useEffect(() => {
        if (!isSupported) {
            return;
        }
        // make sure localParticipant has joined room before applying video processors
        // this ensures that the video processors are not applied on the LocalVideoPreview
        const handleProcessorChange = async () => {
            if (!blurProcessor) {
                blurProcessor = new GaussianBlurBackgroundProcessor({
                    assetsPath: virtualBackgroundAssets,
                });
                await blurProcessor.loadModel();
            }
            if (!virtualBackgroundProcessor) {
                virtualBackgroundProcessor = new VirtualBackgroundProcessor({
                    assetsPath: virtualBackgroundAssets,
                    backgroundImage: await getImage(0),
                    fitType: ImageFit.Cover,
                });
                await virtualBackgroundProcessor.loadModel();
            }
            if (!room?.localParticipant) {
                return;
            }

            if (backgroundSettings.type === 'blur') {
                addProcessor(blurProcessor);
            } else if (
                backgroundSettings.type === 'image' &&
                typeof backgroundSettings.index === 'number'
            ) {
                virtualBackgroundProcessor.backgroundImage = await getImage(
                    backgroundSettings.index
                );
                addProcessor(virtualBackgroundProcessor);
            } else {
                removeProcessor();
            }
        };
        handleProcessorChange();
        window.localStorage.setItem(
            SELECTED_BACKGROUND_SETTINGS_KEY,
            JSON.stringify(backgroundSettings)
        );
    }, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor]);

    return [backgroundSettings, setBackgroundSettings] as const;
}

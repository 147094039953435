import React from 'react';
import { useLocation } from 'react-router-dom';
import DoctorDefault from '../../assets/images/DoctorDefault.png';
import { useAppState } from '../../state';
import UserMenu from '../UserMenu/UserMenu';
import './IntroContainer.scss';

interface IntroContainerProps {
    children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
    const { user } = useAppState();
    const location = useLocation();

    return (
        <div className="intro-block">
            <div className="intro-block__logo">
                <img
                    alt="mosh logo"
                    src={
                        'https://app.getmosh.com.au/assets/logo/logo_dark-a8f123fc6a5cead8cbe90b9d4729a824d99f5ec1fe7b8d4548f6ff46c9096564.svg'
                    }
                ></img>
            </div>
            {user && location.pathname !== '/login' && <UserMenu />}
            <div className="intro-block__container">
                <div className="intro-block__inner-container">
                    <div className="intro-block__doctor-hero-container">
                        <img
                            src={DoctorDefault}
                            className="intro-block__doctor_hero"
                            alt="doctor_default.png"
                        />
                    </div>
                    <div className="intro-block__content">{props.children}</div>
                </div>
            </div>
        </div>
    );
};

export default IntroContainer;

import { service } from '../services/request';

export function getAppointmentById(params: any) {
    return service({
        url: `/api/v1/appointments/${params.appointmentId}`,
        method: 'get',
    });
}

export function startAppointment(data: any) {
    return service({
        url: `/api/v1/appointments/${data.appointmentId}/start_appointment`,
        method: 'post',
    });
}

export function cancelAppointment(appointmentId: string, data: any) {
    return service({
        url: `/api/v1/appointments/${appointmentId}/cancel_appointment`,
        method: 'post',
        data,
    });
}

export function finishAppointment(appointmentId: string, data: any) {
    return service({
        url: `/api/v1/appointments/${appointmentId}/finish_appointment`,
        method: 'post',
        data,
    });
}

export function generateVcAccessToken(data: any) {
    return service({
        url: '/api/v1/appointments/generate_vc_access_token',
        method: 'post',
        data,
    });
}

export function listAppointments(params: any) {
    return service({
        url: '/api/v1/appointments',
        method: 'get',
        params,
    });
}

import React from 'react';

export default function VideoOffIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <defs></defs>
            <path
                fill="#fff"
                d="M91.45,36.27V63.73a3.64,3.64,0,0,1-5.85,2.89L73.18,57.1V71.18a5.92,5.92,0,0,1-5.92,5.92H34.92L73.18,38.84V42.9L85.6,33.38A3.64,3.64,0,0,1,91.45,36.27ZM11.61,76.36,65.08,22.9H14.48a5.93,5.93,0,0,0-5.93,5.92V71.18A5.92,5.92,0,0,0,11.61,76.36Zm2.21,14.77L91.13,13.82a3.5,3.5,0,1,0-4.95-5L8.87,86.18a3.5,3.5,0,0,0,5,4.95Z"
            />
        </svg>
    );
}

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CloseIcon from '../../../assets/icons/CloseIcon';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { useAppState } from '../../../state';
import { DOCTOR_TYPE, USER_TYPE } from '../../../utils/constants';
import './ChatWindowHeader.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            // height: '120px',
            // background: '#F4F4F6',
            // borderBottom: '1px solid #E4E7E9',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 1em',
            borderTopLeftRadius: '32px',
            borderTopRightRadius: '32px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        text: {
            fontWeight: 'bold',
            fontFamily: 'MaisonNeueBook',
            color: theme.palette.primary.main,
            fontSize: '32px',
            paddingTop: '64px',
        },
        closeChatWindow: {
            cursor: 'pointer',
            display: 'flex',
            background: 'transparent',
            border: '0',
            padding: '0.4em',
            position: 'absolute',
            top: 0,
            right: 0,
            margin: '16px',
        },
    })
);

export default function ChatWindowHeader() {
    const { appointment, userType } = useAppState();
    const classes = useStyles();
    const { setIsChatWindowOpen } = useChatContext();
    const [chatName, setChatName] = useState('');

    useEffect(() => {
        let isGp =
            userType === USER_TYPE.DOCTOR &&
            appointment.doctor_user.doctor_type.toLocaleLowerCase() ===
                DOCTOR_TYPE.GP;

        let targetUser =
            userType === USER_TYPE.USER
                ? appointment?.doctor_user
                : appointment?.user;

        let name = `${isGp ? 'Dr ' : ''}${_.capitalize(
            targetUser?.first_name ?? ''
        )} ${_.capitalize(targetUser?.last_name ?? '')}`;

        setChatName(name);
    }, [appointment, userType]);

    return (
        <div className={classes.container + ' chatting_with'}>
            {userType === USER_TYPE.DOCTOR && <h2>Chatting with {chatName}</h2>}
            {userType === USER_TYPE.USER && (
                <h2 className="user-header">Chatting with {chatName}</h2>
            )}
            {userType === USER_TYPE.DOCTOR && (
                <button
                    className={classes.closeChatWindow}
                    onClick={() => setIsChatWindowOpen(false)}
                >
                    <CloseIcon />
                </button>
            )}
        </div>
    );
}

import {
    Button,
    Grid,
    InputLabel,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import React, { ChangeEvent, FormEvent } from 'react';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
    gutterBottom: {
        marginBottom: '1em',
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1.5em 0 3.5em',
        '& div:not(:last-child)': {
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1.5em 0 2em',
        },
    },
    textFieldContainer: {
        width: '100%',
    },
    continueButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

interface RoomNameScreenProps {
    name: string;
    roomName: string;
    setName: (name: string) => void;
    setRoomName: (roomName: string) => void;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
    name,
    roomName,
    setName,
    setRoomName,
    handleSubmit,
}: RoomNameScreenProps) {
    const classes = useStyles();
    const { user } = useAppState();

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRoomName(event.target.value);
    };

    const hasUsername =
        !window.location.search.includes('customIdentity=true') &&
        user?.displayName;

    return (
        <>
            <Typography variant="h5" className={classes.gutterBottom}>
                Waiting room
            </Typography>
            <Typography variant="body1">
                {hasUsername
                    ? "Enter the name of a room you'd like to join."
                    : "Enter your name and the name of a room you'd like to join"}
            </Typography>
            <form onSubmit={handleSubmit}>
                <div className={classes.inputContainer}>
                    {!hasUsername && (
                        <div className={classes.textFieldContainer}>
                            <InputLabel shrink htmlFor="input-user-name">
                                Your Name
                            </InputLabel>
                            <TextField
                                id="input-user-name"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={name}
                                onChange={handleNameChange}
                            />
                        </div>
                    )}
                    <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-room-name">
                            Room Name
                        </InputLabel>
                        <TextField
                            autoCapitalize="false"
                            id="input-room-name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={roomName}
                            onChange={handleRoomNameChange}
                        />
                    </div>
                </div>
                <Grid container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={!name || !roomName}
                        className={classes.continueButton}
                    >
                        Continue
                    </Button>
                </Grid>
            </form>
        </>
    );
}

import React from 'react';

export default function MicOffIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <defs></defs>
            <path
                fill="#fff"
                d="M50,67.67a14.62,14.62,0,0,1-4.83-.82L64.75,47.28v5.65A14.75,14.75,0,0,1,50,67.67ZM64.75,23.23V17.58a14.75,14.75,0,0,0-29.5,0V52.72ZM29.08,58.9a21.8,21.8,0,0,1-.84-6V40.25a3.5,3.5,0,0,0-7,0V52.93a28.48,28.48,0,0,0,2.38,11.43ZM75.27,36.76l-3.51,3.5V52.93A21.75,21.75,0,0,1,39.85,72.17l-5.11,5.11a28.5,28.5,0,0,0,12,4.21,3.41,3.41,0,0,0-.2,1.11v6.14H38.25a3.5,3.5,0,0,0,0,7h23.5a3.5,3.5,0,1,0,0-7H53.5V82.6a3.41,3.41,0,0,0-.2-1.11A28.79,28.79,0,0,0,78.76,52.93V40.25A3.49,3.49,0,0,0,75.27,36.76ZM13.82,91.13,91.13,13.82a3.5,3.5,0,1,0-4.95-5L8.87,86.18a3.5,3.5,0,0,0,5,4.95Z"
            />
        </svg>
    );
}

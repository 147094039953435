import { IconButton } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import BackgroundIcon from '../../../assets/icons/BackgroundIcon';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { isSupported } from '@twilio/video-processors';

export default function BackgroundControlButton(_props: {
    disabled?: boolean;
    className?: string;
}) {
    const { setIsChatWindowOpen } = useChatContext();
    const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen } =
        useVideoContext();

    // Don't show the background selection button if the browser is not supported
    if (!isSupported) {
        return null;
    }

    return (
        <IconButton
            color="secondary"
            onClick={() => {
                setIsBackgroundSelectionOpen(!isBackgroundSelectionOpen);
                setIsChatWindowOpen(false);
            }}
        >
            <BackgroundIcon />
        </IconButton>
    );
}

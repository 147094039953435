import { Hidden, Tab, Tabs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { getUserById } from '../../api/user';
import { useAppState } from '../../state';
import { DOCTOR_TYPE, REBOOK_URL, USER_TYPE } from '../../utils/constants';
import TogglePatientChatButton from '../Buttons/TogglePatientChatButton/TogglePatientChatButton';
import DoctorNoteSection from '../DoctorNoteSection/DoctorNoteSection';
import DoctorNotifications from '../DoctorNotifications/DoctorNotifications';
import DrawerDragger from '../DrawerDragger/DrawerDragger';
import MedicationTab from '../PatientDetail/MedicationTab';
import SummaryTab from '../PatientDetail/SummaryTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

interface DoctorRightPanelMainBlockProps {
    name: string;
}

function DoctorRightPanelMainBlock({ name }: DoctorRightPanelMainBlockProps) {
    const { appointment, setPatientInfo, userType } = useAppState();
    const [value, setValue] = useState(0);
    const [patientName, setPatientName] = useState('');
    const [age, setAge] = useState<number>(0);
    const [gender, setGender] = useState<string>('');
    const [isRebookVisible, setIsRebookVisible] = useState<boolean>(false);

    const onRebookClick = () => {
        window.open(`${REBOOK_URL}?user_id=${appointment.user.uuid}`, '_blank');
    };

    useEffect(() => {
        let isPsychologist =
            userType === USER_TYPE.DOCTOR &&
            appointment.doctor_user.doctor_type.toLocaleLowerCase() ===
                DOCTOR_TYPE.PSYCHOLOGIST;

        setIsRebookVisible(isPsychologist);
    }, [appointment, userType]);

    useEffect(() => {
        setPatientName(_.capitalize(appointment?.user?.first_name ?? ''));

        getUserById({
            id: appointment?.user?.uuid ?? '',
        }).then((response: any) => {
            setPatientInfo(response);

            setGender(_.capitalize(response?.gender ?? ''));

            if (response.dob != null) {
                let split = response.dob.split('-');

                if (split.length === 3) {
                    let ageInstance = dayjs()
                        .year(parseInt(split[2]))
                        .month(parseInt(split[1]) - 1)
                        .date(parseInt(split[0]));
                    let now = dayjs();

                    const userAge = now.diff(ageInstance, 'year', false);
                    setAge(userAge);
                }
            }
        });
    }, []);

    // Here we would like the height of the main container to be the height of the viewport.
    // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
    // not the viewport. This looks bad when the mobile browsers location bar is open.
    // We will dynamically set the height with 'window.innerHeight', which means that this
    // will look good on mobile browsers even after the location bar opens or closes.

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="panel-block">
            <div className="panel-block__drawer-dragger">
                <DrawerDragger />
            </div>
            <div className="panel-block__wrapper">
                <div className="panel-block__patient-info-panel">
                    <h1>{patientName}</h1>
                    <div>
                        {gender}, {age} years old
                    </div>
                </div>

                {/*<div className={classes.infoItem}>MOSH member since {patientSince}</div>*/}
                <div>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Summary"></Tab>
                        {!isRebookVisible && <Tab label="Medications" />}
                    </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                    <SummaryTab />
                </TabPanel>
                {!isRebookVisible && (
                    <TabPanel value={value} index={1}>
                        <MedicationTab />
                    </TabPanel>
                )}
                <div>
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item md={!isRebookVisible ? 12 : 6}>
                            <Hidden smDown>
                                <TogglePatientChatButton
                                    name={name}
                                ></TogglePatientChatButton>
                            </Hidden>
                            <div className="doctor-note-section--mobile">
                                {userType === USER_TYPE.DOCTOR && (
                                    <DoctorNoteSection />
                                )}
                            </div>
                        </Grid>
                        <Grid item md={6} className="btn-rebook-container">
                            {isRebookVisible && (
                                <Button
                                    color="secondary"
                                    className="btn--reg btn--ghost btn-patient-options"
                                    variant="outlined"
                                    onClick={onRebookClick}
                                >
                                    Rebook {patientName}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <DoctorNotifications />
            </div>
        </div>
    );
}

export default DoctorRightPanelMainBlock;

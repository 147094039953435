import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import App from './App';
import './assets/style/index.scss';
import theme from './assets/style/theme';
import { ChatProvider } from './components/ChatProvider';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from './components/VideoProvider';
import AppStateProvider, { useAppState } from './state';
import './types';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';

const VideoApp = () => {
    const { error, setError } = useAppState();
    const connectionOptions = useConnectionOptions();

    return (
        <VideoProvider options={connectionOptions} onError={setError}>
            <ErrorDialog dismissError={() => setError(null)} error={error} />
            <ChatProvider>
                <App />
            </ChatProvider>
        </VideoProvider>
    );
};

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <UnsupportedBrowserWarning>
            <Router>
                <AppStateProvider>
                    <Switch>
                        <PrivateRoute exact path="/">
                            <VideoApp />
                        </PrivateRoute>
                        <PrivateRoute path="/room/:URLRoomName">
                            <VideoApp />
                        </PrivateRoute>
                        <PrivateRoute path="/:UrlAppointmentId">
                            <VideoApp />
                        </PrivateRoute>
                        <Redirect to="/" />
                    </Switch>
                </AppStateProvider>
            </Router>
        </UnsupportedBrowserWarning>
    </MuiThemeProvider>,
    document.getElementById('root')
);
